import React from 'react'

//import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/Header'
import Button from '../components/Button/Button'

const JobPage = ({ data }) => {
  const { title } = data.contentfulJob
  const content = ""

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className='mb-6'>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className='pl-10 mb-6'>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className='list-item-with-arrow pl-4'>{children}</li>
    }
  }

  return (
    <Layout>
      <SEO title={title}>
        <link rel="stylesheet" href="https://assets.calendly.com/assets/external/widget.css" />
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
      </SEO>
      <Header titleBig='Dein neuer Job bei uns?' color='skyblue' />
      <div className='flex justify-center'>
        <div className='flex flex-col flex-wrap max-w-7xl py-24 px-6'>
          <div className='w-full max-w-4xl'>
            <Button to='/jobs' withArrow className='block mb-6' arrowStyle='rotate-180'>zurück</Button>
            <h2 className='mb-12'>Wir suchen: {title}</h2>
          </div>
          <div className='max-w-4xl mb-16'>
            {documentToReactComponents(content, options)}
          </div>
          <div className='mb-16'>
            <h3 className='font-headline mb-6'>Interessiert? Buche direkt dein persönliches Telefonat mit Dennis!</h3>
            <div
              className='calendly-inline-widget'
              data-url='https://calendly.com/swenden/kennenlernen'
              style={{ minWidth:'320px', height:'630px'}}></div>
          </div>
          <div className='mb-16 max-w-4xl'>
            <h3 className='font-headline mb-8 lg:mb-12'>Oder schreib' uns einfach! Wir freuen uns auf dich!</h3>
            <form className='flex flex-col justify-center items-start w-full md:w-10/12 lg:w-7/12' name='job-contact' method='POST' action='/thank-you' data-netlify='true' data-netlify-honeypot='bot-field'>
              <input type='hidden' name='bot-field' />
              <input type='hidden' name='form-name' value='job-contact' />
              <input type='hidden' name='job-titel' value={title} />
              <label className=''>Wie heißt du?</label>
              <input className='input-grey w-full' type='text' name='name' required />
              <label className=''>Wie lautet deine E-Mail-Adresse?</label>
              <input className='input-grey w-full' type='text' name='email' required />
              <label className=''>Und deine Telefonnummer?</label>
              <input className='input-grey w-full' type='text' name='telefonnummer' required />
              <label className=''>Schreib' uns eine Nachricht!</label>
              <textarea className='input-grey w-full' rows='5' name='nachricht' required ></textarea>
              <div className='job-form-dsgvo relative w-full h-40 sm:h-24'>
                <input className='absolute w-full cursor-pointer z-10 opacity-0 h-32 sm:h-20' type='checkbox' name='dsgvo' required />
                <span className='checkbox'></span>
                <span className='text-button block ml-12'>Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich stimme zu, dass meine Angaben zur Kontaktaufnahme und für Rückfragen dauerhaft gespeichert werden.</span>
              </div>
              <Button className='text-button mb-6' href='/datenschutzerklaerung' target='_blank' withArrow ><span className='border-b-2 border-transparent hover:border-black'>Unsere Datenschutzerklärung</span></Button>
              <Button className='self-end btn-filled btn-black' withArrow type='submit'>Abschicken</Button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobPage

export const jobQuery = graphql`
  query ($slug: String!) {
    contentfulJob(slug: {eq: $slug}) {
      title
      childContentfulJobContentRichTextNode {
        json
      }
    }
  }
`
