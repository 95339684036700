import React from 'react'
import PropTypes from 'prop-types'

import './Header.css'

const Header = ({ className, titleBig, stageIntroText, color, textcolor, children }) => {
  return (
    <>
      <header className={`w-full px-0 pb-6 pt-0 px-6 md:px-8 flex justify-center bg-${color} ${className}`}>
        <div className={`flex w-full flex-wrap max-w-6xl`}>
          <div className={`flex w-full flex-wrap justify-center md:w-5/12 xs:mt-8 md:mt-0 pr-6 lg:pr-8 wrapper-stage-title`}>
              <div className='self-center'>
                <h1 className={`stage-title ${textcolor}`}>{titleBig}</h1>
                <p className='leading-relaxed mt-8 font-semibold'>{stageIntroText} </p>
              </div>
          </div>
          <div id='stage-img' className='md:px-6 relative ie-h-full md:absolute overflow-x-hidden right-0 w-full md:w-3/5'>
              {children}
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  titleBig: PropTypes.string,
  color: PropTypes.string,
  textcolor: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  color: 'cream',
}

export default Header
